import { useState } from "react";

export function EditOrderButtonBar({ showDelete, onAction }: { showDelete?: boolean; onAction: (action: "edit" | "decline" | "create" | "update" | "delete") => void }) {
  const [mode, setMode] = useState<"view" | "new" | "edit" | "delete">("view");

  return (
    <div className="flex m-2">
      {mode !== "view" && <p className="ml-auto mr-4 my-auto text-white">{mode === "new" ? "Lagre en ny ordre?" : mode === "edit" ? "Lagre endringer?" : mode === "delete" ? "Er du sikker?" : ""}</p>}
      {mode === "view" ? (
        <div className="flex w-full gap-4">
          <button
            className="ml-0 tp-button-confirm"
            onClick={() => {
              setMode("new");
              onAction("edit");
            }}
          >
            Ny med samme data
          </button>
          <button
            className="ml-0 tp-button-confirm"
            onClick={() => {
              setMode("edit");
              onAction("edit");
            }}
          >
            Endre
          </button>
          {showDelete ? (
            <button
              className="ml-auto tp-button-cancel"
              onClick={() => {
                setMode("delete");
              }}
            >
              Slett
            </button>
          ) : null}
        </div>
      ) : (
        <div>
          <button
            className="ml-4 tp-button-confirm"
            onClick={() => {
              if (mode === "new") {
                onAction("create");
              } else if (mode === "edit") {
                onAction("update");
              } else if (mode === "delete") {
                onAction("delete");
              }
            }}
          >
            Ja
          </button>
          <button
            className="ml-4 tp-button-cancel"
            onClick={() => {
                setMode("view");
                onAction("decline");
            }}
          >
            Nei
          </button>
        </div>
      )}
    </div>
  );
}
