import { useContext, useEffect, useState } from "react";
import { Loading, ModalDialog } from "../../../common/components/dialogs";
import { FlowOrderModel } from "../../models";
import { ModuleContext } from "../../../common/components";
import { FlowBackend } from "../../backends";
import { OrderDetails } from ".";

function OrderDetailsContainer({ orderId, onClose }: { orderId: string; onClose: () => void }) {
  const processor = useContext(ModuleContext);
  const [order, setOrder] = useState<FlowOrderModel>();
  const [orderUpdated, setOrderUpdated] = useState<boolean>();

  useEffect(() => {
    processor?.run(FlowBackend.getOrder, orderId).then((result) => setOrder(result));
  }, []);

  return order ? (
    <OrderDetails
      order={order}
      update={orderUpdated}
      onUpdateOrder={async (updatedOrder, close) => {
        const result = await processor?.run(FlowBackend.updateOrder, updatedOrder);
        setOrderUpdated(true);
        if (close) {
          onClose();
        }
      }}
    />
  ) : (
    <Loading />
  );
}

export function OrderDetailsDialog({ orderId, onClose }: { orderId: string; onClose: () => void }) {
  return (
    <ModalDialog title={`Order #${orderId}`} visible onClose={onClose}>
      <OrderDetailsContainer orderId={orderId} onClose={onClose} />
    </ModalDialog>
  );
}
