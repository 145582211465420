import { FC, useContext, useEffect, useState } from "react";
import { ModuleContainer, ModuleContext } from "../modules/common/components";
import { ActiveOrderList } from "../modules/flows/components/ActiveOrderList";
import { FlowModel } from "../modules/flows/models";
import { FlowBackend } from "../modules/flows/backends";

const IFrameContent: FC<{}> = () => {
  const processor = useContext(ModuleContext);

  const [flows, setFlows] = useState<FlowModel[]>([]);

  useEffect(() => {
    (async () => {
      const fetchedFlows = await processor?.run(FlowBackend.getAllFlows);
      setFlows(fetchedFlows);
    })();
  }, []);

  return (
    <div className="mx-4">
      <ActiveOrderList flows={flows} />
    </div>
  );
};

export const B2BPortalIFrame: FC<{}> = (props) => {
  const [authData, setAuthData] = useState<string>();

  useEffect(() => {
    window.addEventListener("message", handleB2BMessage);
  }, []);

  const handleB2BMessage: EventListener = (message: any) => {
    const b2bAuthData = message?.data?.authData;
    if (b2bAuthData) {
      console.log("B2B authData:", b2bAuthData);
      setAuthData(b2bAuthData);
    }
  };

  return (
    <div className="flex my-4">
      {authData ? (
        <ModuleContainer name="b2bPortalIFrame" auth={{ type: "b2bAuthData", data: authData }}>
          <IFrameContent />
        </ModuleContainer>
      ) : null}
    </div>
  );
};
