import { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/AppContext";
import { ModuleContext } from "../common/components";
import { ExBackend } from "./backends";
import { ExLayout, ExOrderCreated } from "./ExLayout";
import { ExOrderInfoTypeSapValues, ExOrderMapper, ExOrderModel } from "./models";
import { checkRequiredFields } from "./utils/ExOrderHelper";
import { ModalMessage } from "../common/components/dialogs/ModalMessage";

export function ExModule() {
  const [order, setOrder] = useState<ExOrderModel>(ExOrderMapper.empty());

  const processor = useContext(ModuleContext);
  const appContext = useContext(AppContext);

  const [result, setResult] = useState<string>("");
  const [orderTypeDialog, setOrderTypeDialog] = useState<boolean>(false);

  useEffect(() => {
    if (!result) {
      setOrderTypeDialog(true);
    }
  }, [result]);

  async function createOrderHandler() {
    appContext.setNotification(null);
    const requiredFields = checkRequiredFields(order);
    if (requiredFields) {
      appContext.setNotification({ type: "error", text: `Obligatoriske felter: ${requiredFields}` });
    } else if (order.bundles.length + order.products.length === 0) {
      appContext.setNotification({ type: "error", text: `Du må legge til minst ett produkt i listen` });
    } else {
      const createdOrder = await processor?.run(ExBackend.createExOrder, order);
      setResult(`Bestillingen #${createdOrder.orderId} opprettet`);
    }
  }

  return (
    <div className="flex flex-1">
      {result ? (
        <ExOrderCreated
          message={result}
          onBack={() => {
            setOrder(ExOrderMapper.empty());
            setResult("");
          }}
        />
      ) : (
        <div className="flex flex-1 flex-col gap-2">
          {orderTypeDialog ? (
            <ModalMessage>
              <div className="flex m-8 gap-4">
                <label className="my-auto w-1/3">Velg ordre type: </label>
                <select
                  className="text-black rounded-sm p-2"
                  value=""
                  onInput={(e) => {
                    if (e.currentTarget.value) {
                      setOrder({ ...order, order: { ...order.order, typeSap: e.currentTarget.value } });
                      setOrderTypeDialog(false);
                    }
                  }}
                >
                  {[{ value: "", name: "" }, ...ExOrderInfoTypeSapValues].map((item, i) => {
                    return (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </ModalMessage>
          ) : null}
          <ExLayout order={order} onSetOrder={setOrder} mode="new" />
          <button className="ml-auto mr-4 tp-button-confirm" onClick={createOrderHandler}>
            Opprett bestillingen
          </button>
        </div>
      )}
    </div>
  );
}
