import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FlowOrderExecutionStepModel, FlowStepFieldModel, FlowStepModel, OrderAction } from "../../../models";
import { DatePicker } from "@mui/x-date-pickers";

function RoutineField({ label, text }: { label: string; text: string }) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [expandable, setExpandable] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => setExpandable(ref?.current && ref.current?.scrollHeight > ref.current?.offsetHeight ? true : false));

  return (
    <div className="flex flex-col gap-0">
      <h2 className="text-xs mx-2">Routine</h2>
      <textarea readOnly className={`border-2 p-1 rounded resize-none overflow-hidden overflow-y-auto ${expanded ? "h-48" : "h-16"}`} ref={ref} value={text} />
        
      {expandable || expanded ? (
        <button onClick={() => setExpanded(!expanded)}>
          <img className="mx-auto h-8" src={expanded ? "/assets/images/flows/double-up.svg" : "/assets/images/flows/double-down.svg"} />
        </button>
      ) : null}
    </div>
  );
}

export function OrderStepEditor({ stepData, editingOn, onAction }: { stepData: FlowOrderExecutionStepModel; editingOn?: boolean; onAction?: (action: OrderAction, actionData?: FlowOrderExecutionStepModel | string) => void }) {
  const stepModel: FlowStepModel = stepData.model;

  return (
    <div className="flex flex-1 gap-2">
      {stepData.model ? (
        <div className="flex flex-1 flex-col gap-4">
          <RoutineField label="Routine" text={stepData.model.routine} />
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 gap-4 p-2 bg-gray-200 rounded-md">
              <div className="flex flex-1 bg-white rounded-md">
                <div className="flex flex-col gap-2 w-2/3 mx-auto my-4">
                  <h2 className="mx-auto font-bold underline mb-2">Input</h2>
                  {stepModel?.step_data?.in ? (
                    stepData.model.first_step ? (
                      <OrderStepFields
                        key="in"
                        editingOn={editingOn && !stepData.next_status}
                        schema={stepModel.step_data.in}
                        data={stepData.in || {}}
                        confirmRequired={!!stepData.next_status}
                        onSetData={(newData) => {
                          onAction?.("set-data", { ...stepData, in: newData });
                        }}
                      />
                    ) : (
                      <OrderStepFields key="in" schema={stepModel.step_data.in} data={stepData.in || {}} />
                    )
                  ) : null}
                </div>
              </div>
              <div className="flex flex-1 bg-white rounded-md">
                <div className="flex flex-col gap-2 w-2/3 mx-auto my-4">
                  <h2 className="mx-auto font-bold underline mb-2">Output</h2>
                  {stepModel?.step_data?.out ? (
                    <OrderStepFields
                      key="out"
                      editingOn={editingOn && !stepData.next_status}
                      schema={stepModel.step_data.out}
                      data={stepData.out || {}}
                      confirmRequired={!!stepData.next_status}
                      onSetData={(newData) => {
                        onAction?.("set-data", { ...stepData, out: newData });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {editingOn ? (
            <div className="flex mt-auto">
              {/* {stepModel.automated ? (
              <div className="flex my-auto h-12 gap-2">
                <button title="Run this step only" onClick={() => onAction?.("run-step")}>
                  <img className="h-12 w-12 hover:bg-green-200 rounded-full" src="/assets/images/flows/play-step.svg" alt="run-step" />
                </button>
                <button title="Run the rest of the flow" onClick={() => onAction?.("run-flow")}>
                  <img className="h-12 w-12 hover:bg-green-400 rounded-full" src="/assets/images/flows/play.svg" alt="run" />
                </button>
              </div>
            ) : null} */}
            </div>
          ) : null}
        </div>
      ) : (
        <p className="text-red-600 m-auto uppercase">Det er ingen modelldata for dette trinnet!!!</p>
      )}
    </div>
  );
}

function OrderStepFields({ schema, data, editingOn, confirmRequired, onSetData }: { schema: FlowStepFieldModel[]; data: any; editingOn?: boolean; confirmRequired?: boolean; onSetData?: (data: any) => void }) {
  const [formData, setFormData] = useState<any>();
  const [formDataChanged, setFormDataChanged] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<boolean>(false);

  useEffect(() => {
    resetFormData();
  }, [editingOn]);

  function resetFormData() {
    const fd = { ...data };
    for (const field of schema) {
      if (!Object.keys(fd).includes(field.id)) {
        if (field.type === "string" || field.type === "raw data") {
          fd[field.id] = "";
        } else if (field.type === "number") {
          fd[field.id] = 0;
        } else if (field.type === "boolean") {
          fd[field.id] = false;
        } else if (field.type === "date") {
          fd[field.id] = null;
        }
      }
    }
    // setFormData(fd);
    onSetData?.(fd);
  }

  function renderButton(name: string, onClick: () => void) {
    return (
      <button onClick={onClick} className="border-2 rounded-md px-2 py-1 min-w-24">
        {name}
      </button>
    );
  }

  return (
    <>
      {schema.map((dataItemModel, i) => (
        <OrderStepField
          key={dataItemModel.name}
          disabled={!editingOn}
          model={dataItemModel}
          value={data[dataItemModel.id]}
          onSetValue={(value) => {
            onSetData?.({ ...data, [dataItemModel.id]: value });
          }}
        />
      ))}
    </>
  );

  // return confirmMessage ? (
  //   <div className="flex flex-col gap-4 mx-auto mt-2">
  //     <p>Denne handlingen vil endre dataene permanent og tilbakestill saken til dette punktet.</p>
  //     <p className="flex items-center">
  //       {" "}
  //       Er du sikker?
  //       <div className="flex ml-auto gap-2">
  //         {renderButton("Ja", () => {
  //           setConfirmMessage(false);
  //           onSetData?.(formData);
  //           setFormDataChanged(false);
  //         })}
  //         {renderButton("Nei", () => {
  //           setConfirmMessage(false);
  //           resetFormData();
  //           setFormDataChanged(false);
  //         })}
  //       </div>
  //     </p>
  //   </div>
  // ) : formData ? (
  //   <>
  //     {schema.map((dataItemModel, i) => (
  //       <OrderStepField
  //         key={dataItemModel.name}
  //         disabled={!editingOn}
  //         model={dataItemModel}
  //         value={formData[dataItemModel.id]}
  //         onSetValue={(value) => {
  //           setFormData({ ...formData, [dataItemModel.id]: value });
  //           setFormDataChanged(true);
  //         }}
  //       />
  //     ))}
  //     {schema.length && editingOn && formDataChanged ? (
  //       <div className="ml-auto my-2">
  //         {confirmRequired ? (
  //           <div className="flex gap-2">
  //             {renderButton("Oppdater og tilbakestill til dette punktet", () => {
  //               setConfirmMessage(true);
  //               // onSetData?.(formData);
  //               // setFormDataChanged(false);
  //             })}
  //             {renderButton("Avbryt", () => {
  //               resetFormData();
  //               setFormDataChanged(false);
  //             })}
  //           </div>
  //         ) : (
  //           <div className="flex gap-2">
  //             {renderButton("Oppdater", () => {
  //               onSetData?.(formData);
  //               setFormDataChanged(false);
  //             })}
  //             {renderButton("Avbryt", () => {
  //               resetFormData();
  //               setFormDataChanged(false);
  //             })}
  //           </div>
  //         )}
  //       </div>
  //     ) : null}
  //   </>
  // ) : null;
}

function OrderStepField({ disabled, model, value, onSetValue }: { disabled?: boolean; model: FlowStepFieldModel; value: any; onSetValue: (value: any) => void }) {
  // useEffect(() => {
  //   console.log("MODEL", model, value)
  //   if (!value) {
  //     if (model.type === "string") {
  //       onSetValue("");
  //     } else if (model.type === "number") {
  //       onSetValue(0);
  //     } else if (model.type === "boolean") {
  //       onSetValue(false);
  //     }
  //   }
  // }, []);

  if (model.type === "string") {
    return (
      <Tooltip title={model.description}>
        <TextField disabled={disabled} label={model.name} size="small" value={value} onChange={(e) => onSetValue(e.target.value)} />
      </Tooltip>
    );
  } else if (model.type === "text") {
    return (
      <Tooltip title={model.description}>
        <TextField disabled={disabled} label={model.name} size="small" multiline minRows={4} maxRows={32} value={value} onChange={(e) => onSetValue(e.target.value)} />
      </Tooltip>
    );
  } else if (model.type === "number") {
    return (
      <Tooltip title={model.description}>
        <TextField disabled={disabled} label={model.name} type="number" size="small" value={value} onChange={(e) => onSetValue(parseFloat(e.target.value))} />
      </Tooltip>
    );
  } else if (model.type === "boolean") {
    return (
      <Tooltip title={model.description}>
        <FormControlLabel disabled={disabled} control={<Switch checked={value as boolean} onChange={(e) => onSetValue(e.target.checked)} />} label={model.name} />
      </Tooltip>
    );
  } else if (model.type === "date") {
    return (
      <Tooltip title={model.description}>
        <div className="flex flex-1">
          <DatePicker className="flex flex-1" timezone="UTC" label={model.name} disabled={disabled} value={value} onChange={(newValue) => onSetValue(newValue)} />
        </div>
      </Tooltip>
    );
  } else if (model.type === "raw data") {
    return (
      <Tooltip title={model.description}>
        <TextField disabled={disabled} label={model.name} size="small" multiline minRows="5" value={value} onChange={(e) => onSetValue(e.target.value)} />
      </Tooltip>
    );
  } else if (model.type === "select") {
    return (
      <Tooltip title={model.description}>
        <FormControl>
          <InputLabel id={model.id}>{model.name}</InputLabel>
          <Select labelId={model.id} disabled={disabled} label={model.name} value={value} onChange={(e) => onSetValue(e.target.value)}>
            {model.values?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name || item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
  return <></>;
}
