import { ExCustomerMapper, ExCustomerModel, ExDeliveryMapper, ExDeliveryModel, ExOrderBundleLineModel, ExOrderInfoMapper, ExOrderInfoModel, ExOrderProductLineMapper, ExOrderProductLineModel, ExProductMapper } from ".";

export interface ExOrderModel {
  id?: string;
  customer?: ExCustomerModel;
  delivery: ExDeliveryModel;
  order: ExOrderInfoModel;
  bundles: ExOrderBundleLineModel[];
  products: ExOrderProductLineModel[];
}

export const ExOrderMapper = {
  empty: () => {
    return {
      customer: undefined,
      delivery: ExDeliveryMapper.empty(),
      order: ExOrderInfoMapper.empty(),
      bundles: [],
      products: [],
    };
  },

  fromBackendObject: (bo: any) => {
    console.log("BACKEND OBJECT", bo);
    const mapped = {
      customer: ExCustomerMapper.fromBackendObject(bo.customer),
      delivery: ExDeliveryMapper.fromBackendObject(bo.delivery),
      order: ExOrderInfoMapper.fromBackendObject(bo.order, bo.comment),
      bundles: [],
      products: bo.products.map((item: any) => ExOrderProductLineMapper.fromBackendObject(item)),
    };
    console.log("MAPPED OBJECT", mapped);
    return mapped;
  },

  toBackendObject: (order: ExOrderModel) => {
    const productLines: ExOrderProductLineModel[] = [];
    for (const bundle of order.bundles) {
      productLines.push(...bundle.productLines);
    }
    productLines.push(...order.products);

    return {
      customer: ExCustomerMapper.toBackendObject(order.customer),
      delivery: ExDeliveryMapper.toBackendObject(order.delivery),
      ...ExOrderInfoMapper.toBackendObjects(order.order),
      products: productLines.map((item) => ExOrderProductLineMapper.toBackendObject(item)),
    };
  },
};
