import { useContext, useEffect, useState } from "react";
import { UserManagementContext } from "../../../contexts/UserManagementContext";
import { ExOrderInfoPoNrModel, ExOrderInfoTypeSapValues, ExOrderModel } from "../models";
import { CheckboxField, DateField, EditboxField, PoNrField, SelectboxField, TextboxField } from "./common/field";

export function OrderPanel({ order, onSetOrder, mode, readonly }: { order: ExOrderModel; onSetOrder: (newOrder: ExOrderModel) => void; mode: "new" | "edit"; readonly?: boolean }) {
  const userManagement = useContext(UserManagementContext);

  const [sellers, setSellers] = useState<{ id: string; name: string }[]>([]);

  const orderFields = {
    deliveryDate: { label: "Leveringsdato", description: "", value: order.order.deliveryDate, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, deliveryDate: value } }) },
    reference: { label: "Kunde referanse", description: "", value: order.order.reference, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, reference: value } }) },
    requisition: { label: "Kunde rekvisisjon", description: "", value: order.order.requisition, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, requisition: value } }) },
    casenr: { label: "Casenr/Bestillingsnr", description: "", value: order.order.casenr, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, casenr: value } }) },
    sapnr: { label: "SapNr", description: "", value: order.order.sapnr, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, sapnr: value } }) },
    tracking: { label: "Sporingsnummer", description: "", value: order.order.tracking, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, tracking: value } }) },
    ponr: { label: "PoNr", description: "", value: order.order.ponr, setValue: (value: ExOrderInfoPoNrModel[]) => onSetOrder({ ...order, order: { ...order.order, ponr: value } }) },
    invoice: { label: "Samlefaktura", description: "", value: order.order.invoice, setValue: (value: boolean) => onSetOrder({ ...order, order: { ...order.order, invoice: value } }) },
    confirm: { label: "Ordrebekreftelse", description: "", value: order.order.confirm, setValue: (value: boolean) => onSetOrder({ ...order, order: { ...order.order, confirm: value } }) },
    hold: { label: "Leverings sperre", description: "", value: order.order.hold, setValue: (value: boolean) => onSetOrder({ ...order, order: { ...order.order, hold: value } }) },
    invoiceHold: { label: "Faktura sperre", description: "", value: order.order.invoiceHold, setValue: (value: boolean) => onSetOrder({ ...order, order: { ...order.order, invoiceHold: value } }) },
    customerupd: { label: "Oppdatering til kunde", description: "", value: order.order.customerupd, setValue: (value: boolean) => onSetOrder({ ...order, order: { ...order.order, customerupd: value } }) },
    complete: { label: "Komplett levering", description: "", value: order.order.complete, setValue: (value: boolean) => onSetOrder({ ...order, order: { ...order.order, complete: value } }) },
    internal: { label: "Intern kommentar", description: "", value: order.order.comment_internal, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, comment_internal: value } }) },
    lev: { label: "Leverandør kommentar", description: "", value: order.order.comment_lev, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, comment_lev: value } }) },
    seller: { label: "Selger", description: "", value: order.order.seller, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, seller: value } }) },
    typeSap: { label: "Ordre type", description: "", value: order.order.typeSap, setValue: (value: string) => onSetOrder({ ...order, order: { ...order.order, typeSap: value } }) },
  };

  useEffect(() => {
    let allSellers: { id: string; name: string }[] = userManagement.allUsers.filter((item) => item.sellernr && item.sellernr !== "0").map((user) => ({ id: user.id, name: user.name || user.email }));
    allSellers = allSellers.sort((item1, item2) => (item1.name >= item2.name ? 1 : -1));
    allSellers = [{ id: "0", name: "SAP Default" }, ...allSellers];
    setSellers(allSellers);
  }, []);

  return (
    <div className="grid grid-cols-11 gap-8 w-full">
      <div className="flex flex-col gap-1 col-span-3">
        <DateField model={orderFields.deliveryDate} disabled={readonly} readonly={readonly} />
        <EditboxField model={orderFields.reference} readonly={readonly} />
        <EditboxField model={orderFields.requisition} readonly={readonly} />
        <EditboxField model={orderFields.casenr} readonly={readonly} />
      </div>
      <div className="flex flex-col gap-1 col-span-3">
        {mode === "edit" ? (
          <>
            <EditboxField model={orderFields.sapnr} readonly={readonly} />
            <EditboxField model={orderFields.tracking} readonly={readonly} />
            <PoNrField model={orderFields.ponr} readonly={readonly} />
          </>
        ) : null}
        <SelectboxField model={orderFields.typeSap} items={ExOrderInfoTypeSapValues} />
      </div>
      <div className="flex flex-col gap-1 col-span-2">
        <CheckboxField model={orderFields.invoice} disabled={readonly} />
        <CheckboxField model={orderFields.confirm} disabled={readonly} />
        <CheckboxField model={orderFields.hold} disabled={readonly} />
        <CheckboxField model={orderFields.invoiceHold} disabled={readonly} />
        <CheckboxField model={orderFields.customerupd} disabled={readonly} />
        <CheckboxField model={orderFields.complete} disabled={readonly} />
      </div>
      <div className="flex flex-col gap-1 col-span-3">
        <TextboxField model={orderFields.internal} disabled={readonly} />
        <TextboxField model={orderFields.lev} disabled={readonly} />
        <SelectboxField model={orderFields.seller} items={sellers.map((seller) => ({ name: seller.name, value: seller.id }))} disabled={readonly} />
      </div>
    </div>
  );
}
