import { FC, useContext, useEffect, useState } from "react";
import { ModuleContainer, ModuleContext } from "../modules/common/components";
import { FlowCreator, FlowModel, FlowNodeTypeModel, FlowStateModel } from "../modules/flows/models";
import { FlowBackend } from "../modules/flows/backends";
import { FlowDetails } from "../modules/flows/components";
import { ModalDialog } from "../modules/common/components/dialogs";

const FlowButton: FC<{ text: string; onClick: () => void }> = (props) => {
  return (
    <button className="w-96 text-3xl font-bold border-b-2 bg-black rounded-xl text-center p-4" onClick={props.onClick}>
      {props.text}
    </button>
  );
};

const AddFlowButton: FC<{ onAdd: (name: string, prefix: string) => void }> = (props) => {
  const [dialog, setDialog] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [prefix, setPrefix] = useState<string>("");
  const [error, setError] = useState<string>("");

  function reset() {
    setName("");
    setPrefix("");
    setError("");
    setDialog(false);
  }

  return (
    <>
      {dialog ? (
        <ModalDialog flex title="Add new flow" visible onClose={() => setDialog(false)}>
          <div className="flex flex-col gap-2">
            <p className="flex gap-4">
              <label className="w-48">Flow name:</label>
              <input className="text-black" value={name} onChange={(e) => setName(e.currentTarget.value)} />
            </p>
            <p className="flex gap-4">
              <label className="w-48">Status prefix:</label>
              <input className="text-black" value={prefix} onChange={(e) => setPrefix(e.currentTarget.value)} />
            </p>
            {error ? <p className="text-red-400">{error}</p> : null}
            <p className="flex gap-4 ml-auto">
              <button
                onClick={() => {
                  if (name && prefix) {
                    props.onAdd(name, prefix);
                    reset();
                  } else {
                    setError(!name ? "Name cannot be empty" : "Status prefix cannot be empty");
                  }
                }}
              >
                OK
              </button>
              <button onClick={reset}>Cancel</button>
            </p>
          </div>
        </ModalDialog>
      ) : null}
      <FlowButton text="+" onClick={() => setDialog(true)} />
    </>
  );
};

function FlowCard({ flowGroupId, flowName, allNodeTypes }: { flowGroupId: string; flowName: string; allNodeTypes: FlowNodeTypeModel[] }) {
  const processor = useContext(ModuleContext);

  const [flow, setFlow] = useState<FlowModel | null>(null);

  async function loadFlow() {
    const fetchedFlow = await processor?.run(FlowBackend.getFlow, flowGroupId);
    setFlow(FlowCreator().fromBackendObject(fetchedFlow));
  }

  return (
    <>
      {flow ? (
        <ModalDialog title={`Flow: ${flow?.name}`} visible onClose={() => setFlow(null)}>
          <FlowDetails
            flow={flow}
            nodeTypes={allNodeTypes}
            onUpdateFlow={async (flow) => {
              setFlow(null);
              await processor?.run(FlowBackend.updateFlow, flow);
            }}
          />
        </ModalDialog>
      ) : null}
      <FlowButton text={flowName} onClick={loadFlow} />
    </>
  );
}

const IFrameContent: FC<{}> = () => {
  const processor = useContext(ModuleContext);

  const [flows, setFlows] = useState<FlowModel[]>([]);
  const [allNodeTypes, setAllNodeTypes] = useState<FlowNodeTypeModel[]>([]);

  useEffect(() => {
    (async () => {
      await loadAllFlows();
      await loadAllNodeTypes();
    })();
  }, []);

  async function loadAllFlows() {
    const fetchedFlows = await processor?.run(FlowBackend.getAllFlows);
    setFlows(fetchedFlows);
  }

  async function loadAllNodeTypes() {
    const fetchedNodeTypes = await processor?.run(FlowBackend.getAllNodeTypes);
    setAllNodeTypes(fetchedNodeTypes);
  }

  async function addNewFlow(name: string, prefix: string) {
    await processor?.run(FlowBackend.createFlow, name, prefix);
    await loadAllFlows();
  }

  return (
    <div className="flex flex-1 flex-wrap m-4 gap-4">
      {flows.map((flow) => (
        <FlowCard key={flow.flow_id} flowGroupId={flow.group_id} flowName={flow.name} allNodeTypes={allNodeTypes} />
      ))}
      <AddFlowButton onAdd={addNewFlow} />
    </div>
  );
};

export const B2BPortalConfIFrame: FC<{}> = () => {
  const [authData, setAuthData] = useState<string>("");

  useEffect(() => {
    window.addEventListener("message", handleB2BMessage);
  }, []);

  const handleB2BMessage: EventListener = (message: any) => {
    const b2bAuthData = message?.data?.authData;
    if (b2bAuthData) {
      console.log("B2B authData:", b2bAuthData);
      setAuthData(b2bAuthData);
    }
  };

  return authData ? (
    <ModuleContainer name="b2bPortalConfIFrame" auth={{ type: "b2bAuthData", data: authData }}>
      <IFrameContent />
    </ModuleContainer>
  ) : null;
};
