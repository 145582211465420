import { FC, useEffect, useState } from "react";
import { Restricted } from "../common/components/Restricted";
import { InformationPanel, ChatDialog, ExportToCsvButton } from "./components";
import { DailyStats, StatsCard } from "./components/StatsCard";
import { getData } from "../flows/backends/AutologServicesBackend";
import { OrdersByFlow } from "./components/OrdersByFlow";
import { RpaByFlow } from "./components/RpaByFlow";

const dayLength = 24 * 60 * 60 * 1000;

function getFirstDateInMonth() {
  const now = new Date();
  return new Date(now.getTime() - (now.getDate() - 1) * dayLength).toISOString().split("T")[0];
}

function getDatesInRange(startDate: string, endDate: string) {
  const dates: string[] = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  for (let time = start.getTime(); time <= end.getTime(); time += dayLength) {
    dates.push(new Date(time).toISOString());
  }
  return dates;
}

function formatWorktime(worktimeSec: number) {
  const s = worktimeSec % 60;
  const h = Math.floor((worktimeSec - s) / 3600);
  const m = (worktimeSec - s - h * 3600) / 60;
  return `${h}:${m < 10 ? "0" + m.toString() : m}:${s < 10 ? "0" + s.toString() : s}`;
}

function removeOutliers(stats: DailyWorktime[]) {
  const values = stats.map((item) => item.worktime).sort((a, b) => a - b);
  const startIndex = Math.round(values.length / 5) - 1;
  const endIndex = Math.round((values.length * 4) / 5) - 1;
  const cleaned = values.slice(startIndex, endIndex);
  const avg = Math.round(cleaned.reduce((prev, curr) => prev + curr, 0) / cleaned.length);
  return stats.map((item) => ({ date: item.date, worktime: item.worktime > 20 * avg ? avg : item.worktime }));
}

type DailyCount = { date: string; count: number };
type DailyWorktime = { date: string; worktime: number };

export const DashboardModule = () => {
  const [fromDate, setFromDate] = useState<string>(getFirstDateInMonth());
  const [toDate, setToDate] = useState<string>(new Date().toISOString().split("T")[0]);

  const [stats, setStats] = useState<{ hwOrders?: DailyCount[]; flowOrders?: DailyCount[]; sklOrders?: DailyCount[]; hwRpa?: DailyWorktime[]; flowRpa?: DailyWorktime[] }>({
    hwOrders: undefined,
    flowOrders: undefined,
    sklOrders: undefined,
    hwRpa: undefined,
    flowRpa: undefined,
  });

  useEffect(() => {
    (async () => {
      const hwOrders = await getData("autolog/stats/orders/hw");
      const flowOrders = await getData("autolog/stats/orders/flow");
      const sklOrders = await getData("autolog/stats/orders/skl");
      const hwRpa = await getData("autolog/stats/rpa/hw");
      const flowRpa = await getData("autolog/stats/rpa/flows");
      setStats({ hwOrders, flowOrders, sklOrders, hwRpa, flowRpa });
    })();
  }, []);

  const dateRange = getDatesInRange(fromDate, toDate);
  const hwOrders: DailyStats[] = dateRange.map((date) => ({ date, value: stats.hwOrders?.find((item) => item.date === date)?.count || 0 }));
  const flowOrders: DailyStats[] = dateRange.map((date) => ({ date, value: stats.flowOrders?.find((item) => item.date === date)?.count || 0 }));
  const sklOrders: DailyStats[] = dateRange.map((date) => ({ date, value: stats.sklOrders?.find((item) => item.date === date)?.count || 0 }));

  const sumHwOrders = hwOrders.reduce((prev, curr) => prev + curr.value, 0);
  const avgHwOrders = (sumHwOrders / hwOrders.length).toPrecision(2);
  const sumFlowOrders = flowOrders.reduce((prev, curr) => prev + curr.value, 0);
  const avgFlowOrders = (sumFlowOrders / flowOrders.length).toPrecision(2);
  const sumSklOrders = sklOrders.reduce((prev, curr) => prev + curr.value, 0);
  const avgSklOrders = (sumSklOrders / sklOrders.length).toPrecision(2);

  const hwRpa: DailyStats[] = dateRange.map((date) => ({ date, value: stats.hwRpa?.find((item) => item.date === date)?.worktime || 0 }));
  const flowRpa: DailyStats[] = dateRange.map((date) => ({ date, value: stats.flowRpa?.find((item) => item.date === date)?.worktime || 0 }));

  const sumHwRpa = hwRpa.reduce((prev, curr) => prev + curr.value, 0);
  const avgHwRpa = Math.round(sumHwRpa / hwRpa.length);
  const avgHwRpaPerOrder = Math.round(sumHwRpa / sumHwOrders);
  const sumFlowRpa = flowRpa.reduce((prev, curr) => prev + curr.value, 0);
  const avgFlowRpa = Math.round(sumFlowRpa / flowRpa.length);
  const avgFlowRpaPerOrder = Math.round(sumFlowRpa / sumFlowOrders);

  return (
    <div className="flex flex-1">
      <Restricted level={1} module="dashboard">
        <div className="flex flex-col w-2/3 p-4 gap-4">
          {/* <StatsContextComponent>
            <KeyInfoPanel />
          </StatsContextComponent> */}
          <div className="flex gap-8 mx-auto">
            <div>
              <label>Fra</label>
              <input
                className="px-2 ml-4 text-black h-8 rounded-md w-48"
                type="date"
                min="2024-01-01"
                max={toDate}
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.currentTarget.value);
                }}
              />
            </div>
            <div>
              <label>TIl</label>
              <input
                className="px-2 ml-4 text-black h-8 rounded-md w-48"
                type="date"
                min="2024-01-01"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div>
            <h1 className="text-xl font-bold">Orders</h1>
            <div className="flex w-full grid grid-cols-2 p-2 gap-4 border-2 rounded-xl">
              {stats.hwOrders ? (
                <StatsCard
                  title="Hardware"
                  data={hwOrders}
                  stats={[
                    { title: "Total", value: sumHwOrders.toString() },
                    { title: "Daily Average", value: avgHwOrders },
                  ]}
                />
              ) : null}
              {stats.sklOrders ? (
                <StatsCard
                  title="SKL"
                  data={sklOrders}
                  stats={[
                    { title: "Total", value: sumSklOrders.toString() },
                    { title: "Daily Average", value: avgSklOrders },
                  ]}
                />
              ) : null}
              {stats.flowOrders ? (
                <StatsCard
                  title="Flow"
                  data={flowOrders}
                  stats={[
                    { title: "Total", value: sumFlowOrders.toString() },
                    { title: "Daily Average", value: avgFlowOrders },
                  ]}
                  exportButton={<ExportToCsvButton startDate={fromDate} endDate={toDate} />}
                />
              ) : null}
              <OrdersByFlow fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
          <div>
            <h1 className="text-xl font-bold">RPA</h1>
            <div className="flex w-full grid grid-cols-2 p-2 gap-4 border-2 rounded-xl">
              {stats.hwOrders ? (
                <StatsCard
                  title="Hardware"
                  data={hwRpa}
                  stats={[
                    { title: "Total", value: formatWorktime(sumHwRpa) },
                    { title: "Daily Average", value: formatWorktime(avgHwRpa) },
                    { title: "Per Order Average", value: formatWorktime(avgHwRpaPerOrder) },
                  ]}
                />
              ) : null}
              <div></div>
              {stats.flowOrders ? (
                <StatsCard
                  title="Flow"
                  data={flowRpa}
                  stats={[
                    { title: "Total", value: formatWorktime(sumFlowRpa) },
                    { title: "Daily Average", value: formatWorktime(avgFlowRpa) },
                    { title: "Per Order Average", value: formatWorktime(avgFlowRpaPerOrder) },
                  ]}
                />
              ) : null}
              <RpaByFlow fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
        </div>
        <div className="flex w-1/3">
          <InformationPanel />
        </div>
        <ChatDialog />
      </Restricted>
    </div>
  );
};
