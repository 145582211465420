import { ExOrderInfoPoNrModel } from "../../../models";
import { UIField } from "../../../models/UIField";

export const PoNrField = ({ model, readonly }: { model: UIField; readonly?: boolean }) => {
  const ponrs: ExOrderInfoPoNrModel[] = model.value;

  return (
    <div className="flex flex-col m-1">
      {ponrs.length > 0 && (
        <label title={model.description} className={`my-auto w-full border-b-2 w-1/3`}>
          {model.label}
        </label>
      )}
      <div className="flex flex-col w-full mt-2 gap-0.5">
        {ponrs.map((ponr) => (
          <div className="flex" key={ponr.supplier.id}>
            <label className="w-1/2 my-auto">{ponr.supplier.name}</label>
            <input
              type="text"
              className={`w-1/2 px-2 rounded-md border-2 ${model.readonly || readonly ? "bg-transparent text-white" : "text-black"}`}
              readOnly={readonly}
              value={ponr.ponr}
              onInput={(e) => model.setValue(ponrs.map((item) => (item.supplier.id === ponr.supplier.id ? { supplier: item.supplier, ponr: e.currentTarget.value } : item)))}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
