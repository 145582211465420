export const ExOrderInfoTypeSapValues = [
  { value: "zxmt", name: "Sone 1 (montering i utlandet - ZXMT)" },
  { value: "zx20", name: "Sone 2 / Tilbehør (montering i Stavanger / ingen montering)" },
];

export interface ExOrderInfoPoNrModel {
  supplier: { id: string; name: string };
  ponr: string;
}

export interface ExOrderInfoModel {
  deliveryDate: string;
  reference: string;
  requisition: string;
  casenr: string;
  sapnr: string;
  tracking: string;
  ponr: ExOrderInfoPoNrModel[];
  invoice: boolean;
  confirm: boolean;
  hold: boolean;
  invoiceHold: boolean;
  customerupd: boolean;
  complete: boolean;
  comment_internal: string;
  comment_lev: string;
  seller: string;
  typeSap: string;
}

export const ExOrderInfoMapper = {
  empty: () => {
    return {
      deliveryDate: "",
      invoice: false,
      reference: "",
      requisition: "",
      casenr: "",
      sapnr: "",
      tracking: "",
      ponr: [],
      confirm: false,
      hold: false,
      invoiceHold: false,
      customerupd: false,
      complete: false,
      comment_internal: "",
      comment_lev: "",
      seller: "",
      typeSap: "",
    };
  },

  fromBackendObject: (orderBO: any, commentBO: any): ExOrderInfoModel => {
    return {
      ...ExOrderInfoMapper.empty(),
      ...orderBO,
      invoice: orderBO.invoice === "1",
      confirm: orderBO.confirm === "1",
      hold: orderBO.hold === "1",
      invoiceHold: orderBO.invoiceHold === "1",
      customerupd: orderBO.customerupd === "1",
      complete: orderBO.complete === "1",
      comment_internal: commentBO?.internal,
      comment_lev: commentBO?.lev,
    };
  },

  toBackendObjects: (order: ExOrderInfoModel) => {
    return {
      order: {
        ...order,
        ponr: order.ponr,
        invoice: order.invoice ? "1" : "0",
        confirm: order.confirm ? "1" : "0",
        hold: order.hold ? "1" : "0",
        invoiceHold: order.invoiceHold ? "1" : "0",
        customerupd: order.customerupd ? "1" : "0",
        complete: order.complete ? "1" : "0",
      },
      comment: { internal: order.comment_internal, lev: order.comment_lev },
    };
  },
};
