import { useContext, useState } from "react";
import { AppConstantsContext } from "../../../../contexts/AppConstantsContext";
import { ExOrderProductLineModel } from "../../models";
import { PriceCalculator } from "../../utils";
import { AddProductLine } from "./AddProductLine";
import { EditProductLineDialog } from "./EditProductLineDialog";
import { ProductLayout } from "./ProductLayout";

export function ProductLine({ product, edit, highlighted, onMoreClicked }: { product: ExOrderProductLineModel; edit?: boolean; highlighted?: boolean; onMoreClicked?: () => void }) {
  const appConstants = useContext(AppConstantsContext);
  const [pointerOver, setPointerOver] = useState(false);

  const deliveryOptions = appConstants.deliveryAlternatives;

  return (
    <div className={`flex ml-8 ${product.custom && "text-rose-400"}`} onMouseEnter={() => setPointerOver(true)} onMouseLeave={() => setPointerOver(false)}>
      <div className="w-full cursor-pointer mt-1">
        {/* <div className={`w-full cursor-pointer ${pointerOver && "text-black"} ${highlighted && "bg-black"} mt-1`}></div> */}
        <ProductLayout>
          <div className="w-full">{product.number}</div>
          <div className="w-full">{product.ean}</div>
          <div className="w-full">{product.description}</div>
          <div className="w-full">{product.levname}</div>
          <div className="w-full">{deliveryOptions.find((item) => item.lev_alt === product.lev_alt)?.description}</div>
          <div className="w-full">{product.bid}</div>
          <div className="flex w-full">{product.moms ? <img className="w-5 h-5 ml-auto" src="/assets/images/check.svg" alt="check" /> : null}</div>
          <div className="w-full text-right mr-1">{product.quantity}</div>
          <div className="w-full text-right mr-1">{product.calculation}</div>
          <div className="w-full text-right mr-1">{product.price}</div>
          <div className="w-full text-right mr-1">{product.outPrice}</div>
          <div className="w-full text-right mr-1">{PriceCalculator.GetTotalPricesFormated(product.price, product.outPrice, product.quantity)}</div>
        </ProductLayout>
      </div>
      <button disabled={!edit} className="rounded-md w-8 h-8 flex my-auto" onClick={onMoreClicked}>
        {edit && <img className="h-5 m-auto" src="/assets/images/more.svg" alt="remove product" />}
      </button>
    </div>
  );
}

export function ProductLines({ readonly, productLines, onSetProductLines }: { readonly?: boolean; productLines: ExOrderProductLineModel[]; onSetProductLines: (productLines: ExOrderProductLineModel[]) => void }) {
  const [selectedProductLine, setSelectedProductLine] = useState<ExOrderProductLineModel>();
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  function moreClickHandler(productLine: ExOrderProductLineModel) {
    setSelectedProductLine(productLine);
    setShowEditDialog(true);
  }

  function updateSelectedProductLine(updated: ExOrderProductLineModel) {
    onSetProductLines(productLines.map((item) => (item === selectedProductLine ? updated : item)));
  }

  function deleteSelectedProductLine() {
    onSetProductLines(productLines.filter((item) => item !== selectedProductLine));
  }

  return (
    <>
      {selectedProductLine && showEditDialog ? (
        <EditProductLineDialog productLine={selectedProductLine} onClose={() => setShowEditDialog(false)} onUpdate={(productLine) => updateSelectedProductLine(productLine)} onDelete={() => deleteSelectedProductLine()} />
      ) : null}
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full">
          {productLines?.map((productLine) => (
            <ProductLine key={productLine.id} product={productLine} edit={!readonly} onMoreClicked={() => moreClickHandler(productLine)} />
          ))}
        </div>
        {!readonly ? <AddProductLine onAdd={(newLine: ExOrderProductLineModel) => onSetProductLines([...productLines, newLine])} /> : null}
      </div>
    </>
  );
}
