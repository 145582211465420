import { useContext, useState } from "react";
import { EditOrderButtonBar } from "../../../components/common/EditOrderButtonBar";
import { ModalDialog } from "../../common/components/dialogs";
import { ExLayout } from "../ExLayout";
import { ExOrderModel } from "../models";
import { ExBackend } from "../backends";
import { ModuleContext } from "../../common/components";

export function EditExOrderDialog({ order, onClose }: { order: ExOrderModel; onClose: () => void }) {
  const processor = useContext(ModuleContext);
  
  const [currentOrder, setCurrentOrder] = useState<ExOrderModel>(order);
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <ModalDialog title={`Order #${order.id}`} onClose={onClose} visible={true} flex>
      <div className="flex flex-col">
        <ExLayout order={currentOrder} onSetOrder={(changed) => setCurrentOrder(changed)} mode={"edit"} readonly={!edit} />
        <EditOrderButtonBar
          onAction={async (action) => {
            if (action === "edit") {
              setEdit(true);
            } else if(action === "decline") {
              onClose();
            } else if(action === "create") {
              const createdOrder = await processor?.run(ExBackend.createExOrder, currentOrder);
              onClose();
            }
          }}
        />
      </div>
    </ModalDialog>
  );
}
