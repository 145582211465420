import { FC, useContext, useEffect, useState } from "react";
import { FlowBackend } from "./backends";
import { ModuleContext } from "../common/components/ModuleContainer";
import { FlowModel } from "./models";
import { ActiveOrderList, FlowsDashboard } from "./components";

export const FlowsModule: FC<{}> = () => {
  const processor = useContext(ModuleContext);

  const [view, setView] = useState<"list" | "dashboard">("list");
  const [flows, setFlows] = useState<FlowModel[]>([]);

  useEffect(() => {
    (async () => {
      const fetchedFlows = await processor?.run(FlowBackend.getAllFlows);
      setFlows(fetchedFlows);
    })();
  }, []);

  return flows?.length > 0 ? (
    <div className="flex flex-1 flex-col">
      <div className="flex w-full p-4 ">
        <button onClick={() => setView(view === "list" ? "dashboard" : "list")}>
          <img className="h-8 w-8" src={view === "list" ? "/assets/images/flows/toggle-off.svg" : "/assets/images/flows/toggle-on.svg"} alt="refresh" />
        </button>
      </div>
      {view === "list" ? (
        <div className="mx-4">
          <ActiveOrderList flows={flows} />
        </div>
      ) : null}
      {view === "dashboard" ? (
        <div className="mx-8">
          <FlowsDashboard flows={flows} />
        </div>
      ) : null}
    </div>
  ) : null;
};
