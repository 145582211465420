import { TELENOR_PORTAL_SERVICES_URL, ENV } from "../../../env";

const baseUrl = `${TELENOR_PORTAL_SERVICES_URL}`;

export async function getData(path: string, userId?: string): Promise<any> {
  const headers = new Headers();
  if(userId) {
    headers.append("Auth0-User", userId);
  }
  // const res = await fetch(`${baseUrl}/${path}?env=${ENV}`, {
  const res = await fetch(`${baseUrl}/${path}`, {
    method: "GET",
    headers: headers
  });
  const data = await res.json();
  return data;
}
