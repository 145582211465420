import { ExOrderBundleLineMapper, ExOrderMapper, ExOrderModel, ExProductDeliveryOption } from "../models";
import { BundleLines, ProductBundler, ProductLines, ProductListHeader } from "./product-list";
import { TotalPrice } from "../../common/components/panels/TotalPrice";
import { getTotals } from "../utils/ExOrderHelper";

export function ProductListPanel({ order, onSetOrder, showBundles, readonly }: { order: ExOrderModel; onSetOrder: (updatedOrder: ExOrderModel) => void; showBundles?: boolean; readonly?: boolean }) {
  const { totalPrice, totalProfit } = getTotals({ ...ExOrderMapper.empty(), bundles: order.bundles, products: order.products });

  return (
    <div className="w-full text-sm">
      {showBundles ? <ProductBundler onAddBundle={(bundle) => onSetOrder({ ...order, bundles: [...order.bundles, ExOrderBundleLineMapper.fromProductBundle(bundle)] })} /> : null}
      <ProductListHeader />
      {showBundles ? <BundleLines bundles={order.bundles} onSetBundles={(bundles) => onSetOrder({ ...order, bundles: bundles })} /> : null}
      <ProductLines readonly={readonly} productLines={order.products} onSetProductLines={(products) => onSetOrder({ ...order, products: products })} />
      <div className="flex -mr-2 mt-2">
        <TotalPrice price={totalPrice} profit={totalProfit} />
      </div>
    </div>
  );
}
