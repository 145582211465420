import { CollapsibleCard } from "./components/common/CollapsibleCard";
import { CustomerPanel, OrderPanel, ProductListPanel } from "./components";
import { ExOrderModel } from "./models";
import { getOrderedSuppliers } from "./utils/ExOrderHelper";

export function ExOrderCreated({ message, onBack }: { message: string; onBack: () => void }) {
  return (
    <div className="flex flex-col m-auto gap-2">
      <p>{message}</p>
      <button
        className="ibutton rounded-md px-2 py-1"
        onClick={() => {
          console.log("CLICK");
          onBack();
        }}
      >
        Tilbake
      </button>
    </div>
  );
}

export function ExLayout({ order, onSetOrder, mode, readonly }: { order: ExOrderModel; onSetOrder: (newOrder: ExOrderModel) => void; mode: "new" | "edit"; readonly?: boolean }) {
  function fixPoNrsAndSetOrder(order: ExOrderModel) {
    const ponrs = order.order.ponr;
    const orderedSuppliers = getOrderedSuppliers(order);
    const updatedPoNrs = orderedSuppliers.map((item) => ({ supplier: item, ponr: ponrs.find((ponr) => ponr.supplier.id === item.id)?.ponr || "" }));
    onSetOrder({ ...order, order: { ...order.order, ponr: updatedPoNrs } });
  }

  return (
    <div className="flex flex-1 flex-col gap-2 p-2">
      <CollapsibleCard title="Kunde">
        <CustomerPanel order={order} onSetOrder={onSetOrder} readonly={readonly} />
      </CollapsibleCard>
      <CollapsibleCard title="Ordre">
        <OrderPanel order={order} onSetOrder={onSetOrder} mode={mode} readonly={readonly} />
      </CollapsibleCard>
      <CollapsibleCard title="Produkter">
        <ProductListPanel order={order} onSetOrder={fixPoNrsAndSetOrder} showBundles={mode==="new"} readonly={readonly} />
      </CollapsibleCard>
    </div>
  );
}
