import { createContext, FC, ReactNode, useContext, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import { Loading } from "./dialogs/Loading";

export type AuthData = { type: "auth0UserId" | "b2bAuthData"; data: string };

export const ModuleContext = createContext<{ name: string; run: (process: (...a: any[]) => Promise<any>, ...args: any[]) => Promise<any>; runSilently: (process: (...a: any[]) => Promise<any>, ...args: any[]) => Promise<any> } | null>(null);

export const ModuleContainer: FC<{ name: string; children: ReactNode; auth?: AuthData }> = ({ name, children, auth }) => {
  const appContext = useContext(AppContext);

  const [processStatus, setProcessStatus] = useState<{ state: "" | "processing" | "error" }>({ state: "" });

  async function runProcess(process: (...args: any[]) => Promise<any>, ...args: any[]) {
    try {
      setProcessStatus({ state: "processing" });
      const result = await process(...args, auth || appContext.login?.uid);
      return result;
    } catch (err: any) {
      setProcessStatus({ state: "error" });
      appContext.setNotification({ type: "error", text: err.message });
    } finally {
      setProcessStatus({ state: "" });
    }
  }

  async function runProcessSilently(process: (...args: any[]) => Promise<any>, ...args: any[]) {
    try {
      const result = await process(...args, auth || appContext.login?.uid);
      return result;
    } catch (err: any) {
      setProcessStatus({ state: "error" });
      appContext.setNotification({ type: "error", text: err.message });
    } finally {
      setProcessStatus({ state: "" });
    }
  }

  return (
    <div className="flex flex-1 h-full">
      {processStatus.state === "processing" && <Loading />}
      <ModuleContext.Provider value={{ name, run: runProcess, runSilently: runProcessSilently }}>{children}</ModuleContext.Provider>
    </div>
  );
};
