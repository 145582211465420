import { TELENOR_API_URL } from "../../../env";
import { AuthData } from "../../common/components";
import { FlowCreator, FlowModel, FlowNodeTypeModel, FlowOrderCreator, FlowOrderInfoCreator, FlowOrderInfoModel, FlowOrderModel, FlowStateModel } from "../models";

const baseUrl = `${TELENOR_API_URL}/flows`;

function getHeaders(auth?: AuthData | string) {
  const headers = new Headers();
  if (auth) {
    if (typeof auth === "string") {
      headers.append("Auth0-User", auth);
    } else {
      headers.append(auth.type === "b2bAuthData" ? "authData" : "Auth0-User", auth.data || "");
    }
  }
  return headers;
}

export async function getAllFlows(auth?: AuthData | string): Promise<FlowModel[]> {
  const res = await fetch(`${baseUrl}`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data as FlowModel[];
}

export async function getFlow(flowId: string, auth?: AuthData | string) {
  const res = await fetch(`${baseUrl}/${flowId}`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data;
}

export async function createFlow(name: string, prefix: string, auth?: AuthData | string): Promise<void> {
  const res = await fetch(`${baseUrl}`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ name: name, status_prefix: prefix }),
  });
  const data = await res.json();
  console.log("CREATED FLOW", data);
}

export async function updateFlow(flow: FlowModel, auth?: AuthData | string): Promise<FlowModel> {
  const res = await fetch(`${baseUrl}/${flow.group_id}`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ steps: flow.steps?.map((item) => ({ ...item, id: "", first_step: item.first_step ? "1" : "0" })) }),
  });
  const data = await res.json();

  return { ...flow, ...FlowCreator().fromBackendObject(data) };
}

export async function getAllNodeTypes(auth?: AuthData | string): Promise<FlowNodeTypeModel[]> {
  const res = await fetch(`${baseUrl}/nodes`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data as FlowNodeTypeModel[];
}

export async function getAllFlowStates(auth?: AuthData | string): Promise<FlowStateModel[]> {
  const res = await fetch(`${baseUrl}/states`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data as FlowStateModel[];
}

export async function getAllOrderInfos(flowId: string, auth?: AuthData | string): Promise<FlowOrderInfoModel[]> {
  const creator = FlowOrderInfoCreator();
  const res = await fetch(`${baseUrl}/${flowId}/orders`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data.map((item: any) => creator.fromBackendObject(item));
}

export async function getActiveOrderInfos(auth?: AuthData | string): Promise<FlowOrderInfoModel[]> {
  const creator = FlowOrderInfoCreator();
  const res = await fetch(`${baseUrl}/orders/overview?active=1`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data.map((item: any) => creator.fromBackendObject(item));
}

export async function getOrder(orderId: string, auth?: AuthData | string): Promise<FlowOrderModel> {
  const res = await fetch(`${baseUrl}/orders/${orderId}`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return FlowOrderCreator().fromBackendObject(data);
}

export async function createOrder(order: FlowOrderModel, auth?: AuthData | string): Promise<FlowOrderModel> {
  console.log("CREATE ORDER", order);
  const res = await fetch(`${baseUrl}/orders`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify(FlowOrderCreator().toBackendObject(order)),
  });
  const data = await res.json();
  console.log("ORDER CREATED", data);
  return FlowOrderCreator().fromBackendObject(data.order.raw_data);
}

export async function updateOrder(order: FlowOrderModel, auth?: AuthData | string) {
  const res = await fetch(`${baseUrl}/orders/${order.id}`, {
    method: "PUT",
    headers: getHeaders(auth),
    body: JSON.stringify(FlowOrderCreator().toBackendObject(order)),
  });
  const data = await res.json();
  return data;
}

export async function claimOrder(orderId: string, auth?: AuthData | string) {
  const res = await fetch(`${baseUrl}/orders/${orderId}/claim`, {
    method: "PUT",
    headers: getHeaders(auth),
  });
  if (res.ok) {
    return true;
  } else {
    throw new Error("Order already claimed");
  }
}

export async function unclaimOrder(orderId: string, auth?: AuthData | string) {
  const res = await fetch(`${baseUrl}/orders/${orderId}/claim`, {
    method: "DELETE",
    headers: getHeaders(auth),
  });
  if (res.ok) {
    return true;
  } else {
    throw new Error("Can't unclaim the order");
  }
}

export async function tagOrder(orderId: string, tag: any, auth?: AuthData | string) {
  const res = await fetch(`${baseUrl}/orders/${orderId}/tag`, {
    method: "PUT",
    headers: getHeaders(auth),
    body: JSON.stringify(tag),
  });
  if (res.ok) {
    return true;
  } else {
    throw new Error("Can't tag the order");
  }
}

export async function runOrderSteps(order: FlowOrderModel, stepId: string, stop: boolean, auth?: AuthData | string) {
  await updateOrder(order, auth);
  const res = await fetch(`${baseUrl}/orders/${order.id}/run?status=${stepId}&stop=${stop ? "1" : "0"}`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const data = await res.json();
  return data;
}
