import { FC, useContext } from "react";
import { getData } from "../../flows/backends/AutologServicesBackend";
import AppContext from "../../../contexts/AppContext";

const downloadCSV = (data: any[]) => {
  let csvContent = ["Id", "Flow", "Date", "Casenr", "AISummary", "AICategory", "AIExplanation"].join(";") + "\n"; // Create headers
  csvContent += data.map((row: any) => [row.id, row.name, row.regdate?.split("T")[0], row.info?.casenr, row.info?.Aisummary, row.info?.Aicategory, row.info?.Aiexplanation].join(";")).join("\n"); // Convert rows
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  // Create a temporary link and trigger download
  const a = document.createElement("a");
  a.href = url;
  a.download = "data.csv";
  a.click();

  // Clean up
  URL.revokeObjectURL(url);
};

export const ExportToCsvButton: FC<{ startDate: string; endDate: string; flowGroupId?: number }> = (props) => {
  const appContext = useContext(AppContext);

  return (
    <button className="flex"
      onClick={async () => {
        const data = await getData(`autolog/export/flows?startDate=${props.startDate}&endDate=${props.endDate}${props.flowGroupId ? "&flowGroupId=" + props.flowGroupId : ""}`, appContext.login?.uid);
        console.log(data);
        downloadCSV(data);
      }}
    >
      <img className="h-8" src="/assets/images/csv-export.png" />
    </button>
  );
};
